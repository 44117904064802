import { createI18n } from "vue-i18n";

import translationDe from "../locales/de.json";
import translationEn from "../locales/en.json";

const availableLocales = ["de", "en"];

const i18n = createI18n({
  legacy: false,
  locale: getLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {
    de: translationDe,
    en: translationEn
  }
});

export default i18n;

function formatLocaleString(locale: string) {
  return locale.split("-")[0];
}

function findAvailableLocale(locales: readonly string[]): string | null {
  if(!locales || !locales.length || locales.length < 1) return null;
  const filtered = locales.map(formatLocaleString)
    .filter(locale => availableLocales.includes(locale));
  return filtered.length > 0 ? filtered[0] : null;
}

function filterValidLocale(locale: string): string | null {
  return availableLocales.includes(locale) ? locale : null;
}

export function getLocale(): string {
  const current: string | null = localStorage.getItem("locale");

  if (current && filterValidLocale(current)) return current;

  return findAvailableLocale(navigator.languages) || process.env.VUE_APP_I18N_LOCALE || "en";
}

export function setLocale(l: string): string {
  localStorage.setItem("locale", l);
  i18n.global.locale.value = l as "de" | "en";
  return l;
}