import { createApp } from "vue";
import App from "./App.vue";
import router from "./plugins/router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "./assets/css/theme.css"

import { loadFonts } from "./plugins/webfontloader";

loadFonts();

createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount("#app");
