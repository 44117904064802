<template>
  <v-footer
    role="contentinfo"
    padless
    order="1"
    style="max-height: 150px; position: absolute; bottom: 0; width: 100%; padding-top: 15px"
    color="tertiary-container"
    class="on-tertiary-container--text"
  >
    <v-container
      width="100%"
      class="text-center py-0"
    >
      <v-card-text class="py-0">
        <v-btn
          v-for="link in links"
          :key="link.title"
          class="mx-4"
          variant="plain"
          icon
          @click="router.push(link.to)"
          :aria-label="$t(link.title)"
        >
          <v-tooltip
            activator="parent"
            location="bottom">
            {{ $t(link.title) }}
          </v-tooltip>
          <v-icon>{{ link.icon }}</v-icon>
        </v-btn>
      </v-card-text>


      <v-divider />

      <v-card-text>
        {{ new Date().getFullYear() }} &copy; Hendrik Wagner
        <br/>
        <v-btn @click="router.push('/imprint/')" variant="plain" >{{ $t("imprint.title") }}</v-btn>
      </v-card-text>
    </v-container>
  </v-footer>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const links = [
  {
    icon: "mdi-home",
    title: "home.title",
    to: "/",
  },
  {
    icon: "mdi-book",
    title: "projects.title",
    to: "/projects/",
  },
];
</script>

<style scoped>
</style>