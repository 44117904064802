<template>
  <v-container>
    <v-row class="text-center justify-center">
      <!-- p5js title -->
      <v-col cols="12" class="mt-4 mb-2 d-flex justify-center">
        <home-interactive />
      </v-col>

      <!-- description -->

      <v-col xxl="6" xl="8" md="8" sm="10" xs="12" class="mt-4 mb-2">
        <v-card class="ma-3 pa-5" color="secondary-container">
          <p class="on-secondary-container--text">
            {{ $t("app.description") }}
          </p>
        </v-card>
      </v-col>

      <v-col cols="12" class="mt-4 mb-2">
        <v-card class="ma-3 pa-5" color="secondary-container">
          <v-btn
            @click="router.push('/projects/')"
            min-width="30%"
            disabled
            color="secondary"
          >
            <v-icon class="mr-2">mdi-book</v-icon>
            <span>{{ $t("projects.title") }}</span>
          </v-btn>
          <v-divider class="my-4" />
          <p class="on-secondary-container--text">
            {{ $t("projects.info_disabled") }}
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <!-- 200 px empty space -->
  <v-spacer style="height: 200px" />
</template>

<script setup>
import { useRouter } from "vue-router";
import HomeInteractive from "@/components/interactives/HomeInteractive.vue";

const router = useRouter();
</script>
