export default {
  "app": {
    "auto_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hey! Ich bin ein Fullstack-Softwareentwickler aus Deutschland. Hier findest du einige meiner Projekte und Kontaktmöglichkeiten."])},
    "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docs"])},
    "mobile_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit gibt es Probleme, die Seite unter iOS zu verwenden - Änderungen werden erst nach neu Laden der Seite korrekt angezeigt."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hendrik Wagner"])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])},
    "page_not_found_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angeforderte Seite konnte nicht gefunden werden."])},
    "page_not_found_go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Startseite"])},
    "skip_to_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Inhalt springen"])},
    "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbschema"])}
  },
  "buttons": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])}
  },
  "contact": {
    "choose_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktweg auswählen:"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail ist ungültig"])},
    "email_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail erforderlich"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
    "message_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht erforderlich"])},
    "message_too_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht ist zu kurz"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name erforderlich"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])}
  },
  "i18n": {
    "change_to_de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Deutsch wechseln"])},
    "change_to_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Englisch wechseln"])}
  },
  "imprint": {
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben gemäß § 5 TMG"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])}
  },
  "lasergame2": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laser Tempel 2"])}
  },
  "projects": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier finden Sie einige meiner Projekte."])},
    "technologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologien"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt ansehen"])},
    "info_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Seite zu Projekten befindet sich derzeit in Überarbeitung."])}
  }
}