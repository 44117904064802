import webFontLoader from 'webfontloader';

export async function loadFonts() {
  webFontLoader.load({
    custom: {
      families: ["Roboto:100,300,400,500,700,900&display=swap"],
      urls: ['/assets/fonts/fonts.css']
    },
  });
}
