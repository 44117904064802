<template>
  <p5-frame
    v-on="{ setup, draw, mousereleased }"
  />
</template>

<script setup>
import P5Frame from "@/components/p5Frame.vue";
import { setColors } from "@/plugins/colors";
import { theme } from "@/plugins/theme";

const name = "Hendrik Wagner";
const letters = [];
const balls = [];


function setup(p5, canvasParentRef) {
  p5.createCanvas(p5.min(1000, p5.windowWidth), p5.min(300, p5.windowHeight)).parent(canvasParentRef);
  p5.pixelDensity(2);
  p5.textSize(p5.min(p5.width < 800 ? 42 : 64));
  p5.textAlign(p5.CENTER, p5.CENTER);
  let totalWidth = 0;
  for (let i = 0; i < name.length; i++) {
    totalWidth += p5.textWidth(name.charAt(i));
  }
  const leftX = (p5.width - totalWidth) / 2;

  for (let i = 0; i < name.length; i++) {
    letters.push(new Letter(
      leftX + p5.textWidth(name.substring(0, i)),
      p5.height / 2,
      name.charAt(i),
      i,
      p5.textWidth(name.charAt(i)),
      p5
    ));
  }

  for (let i = 0; i < 5; i++) {
    balls.push(new Ball(p5));
  }
}

function draw(p5) {
  p5.clear();
  for (let i = 0; i < letters.length; i++) {
    for (let j = i + 1; j < letters.length; j++) {
      letters[i].checkCollision(letters[j], p5);
    }
  }

  for (let i = 0; i < letters.length; i++) {
    letters[i].repel(p5.mouseX, p5.mouseY, 160, 1, p5);
    letters[i].update(p5);
    letters[i].draw(p5);
  }

  for (let i = 0; i < balls.length; i++) {
    balls[i].update(p5);
    balls[i].draw(p5);
    // repel letters
    for (let j = 0; j < letters.length; j++) {
      letters[j].repelBall(balls[i], p5);
    }
    // repel mouse
    balls[i].repel(p5.mouseX, p5.mouseY, 60, 1, p5);
    // repel other balls
    for (let j = i + 1; j < balls.length; j++) {
      balls[i].checkCollision(balls[j], p5);
    }
  }

  // update colors
  const newColors = [];
  for (let i = 0; i < letters.length; i++) {
    // format color to #rrggbb
    newColors.push(p5.color(letters[i].color).toString("#rrggbb"));
  }
  setColors(newColors);
}

function mousereleased(p5) {
  for (let i = 0; i < letters.length; i++) {
    letters[i].repel(p5.mouseX, p5.mouseY, 250, 40, p5);
  }
  for (let i = 0; i < balls.length; i++) {
    balls[i].repel(p5.mouseX, p5.mouseY, 250, 40, p5);
  }
}

function calculateFade(x, y, radius, color, p5) {
  // return alpha based on distance to edges of canvas
  const dists = [x - radius, (p5.width - radius) - x, y - radius, (p5.height - radius) - y];
  const minDist = p5.min(dists) > 0 ? p5.min(dists) : 0;

  const alpha = p5.map(minDist, 0, radius * 3, 0, 1);
  return p5.color(color.levels[0], color.levels[1], color.levels[2], alpha * color.levels[3]);
}

class Letter {
  constructor(x, y, letter, index, w, p5) {
    this.x = this.trueX = x;
    this.y = this.trueY = y;
    this.vx = 0;
    this.vy = 0;
    this.size = p5.width < 800 ? 42 : 64;
    this.letter = letter;
    this.index = index;
    this.radius = w / 2.3;
    this.color = this.baseColor = p5.color(p5.random(175, 215), p5.random(175, 215), p5.random(175, 215), 200);
    this.targetColor = p5.color(p5.random(255), p5.random(255), p5.random(255));
  }

  getBaseColor(p5) {
    // depending on current theme, return base color (dark theme) or a darker version of base color (light theme)
    if (theme.value === "dark") {
      return this.baseColor;
    } else {
      return p5.color(this.baseColor.levels[0] * 0.5, this.baseColor.levels[1] * 0.5, this.baseColor.levels[2] * 0.5, 225);
    }
  }

  draw(p5) {
    p5.textSize(this.size);
    p5.fill(this.color);
    p5.text(this.letter, this.x, this.y);
  }

  update(p5) {
    if (this.x !== this.trueX) {
      this.vx += (this.trueX - this.x) * 0.01;
    }

    if (this.y !== this.trueY) {
      this.vy += (this.trueY - this.y) * 0.01;
    }

    this.vx *= 0.9;
    this.vy *= 0.9;

    this.x += this.vx;
    this.y += this.vy;

    this.updateColor(p5);
  }

  repel(x, y, maxDistance, maxForce, p5) {
    if (!x && !y) return; // prevent uninitialized movement
    const d = p5.dist(x, y, this.x, this.y);
    if (d < maxDistance) {
      const dx = x - this.x;
      const dy = y - this.y;
      const angle = p5.atan2(dy, dx);
      const force = p5.map(d, 0, maxDistance, maxForce, 0);
      this.vx -= p5.cos(angle) * force;
      this.vy -= p5.sin(angle) * force;
    }
  }

  repelBall(ball, p5) {
    const d = p5.dist(ball.x, ball.y, this.x, this.y);
    if (d < ball.radius + this.radius) {
      const dx = ball.x - this.x;
      const dy = ball.y - this.y;
      const angle = p5.atan2(dy, dx);
      const force = p5.map(d, 0, ball.radius + this.radius * 2, 0.95, 0);
      this.vx -= p5.cos(angle) * force;
      this.vy -= p5.sin(angle) * force;
      this.targetColor = ball.color;
    }
  }

  checkCollision(other, p5) {
    const d = p5.dist(this.x, this.y, other.x, other.y);
    const minDist = this.radius + other.radius;
    if (d < minDist) { // collision detected
      const angle = p5.atan2(other.y - this.y, other.x - this.x); // angle of collision
      const overlap = minDist - d; // how much the letters overlap (approximation)

      this.x -= p5.cos(angle) * overlap / 2; // move the balls apart ...
      this.y -= p5.sin(angle) * overlap / 2;
      other.x += p5.cos(angle) * overlap / 2;
      other.y += p5.sin(angle) * overlap / 2;

      const a1 = p5.atan2(this.vy, this.vx); // angle of velocity of ball 1
      const a2 = p5.atan2(other.vy, other.vx); // and ball 2
      const v1 = p5.sqrt(this.vx * this.vx + this.vy * this.vy); // current velocity of ball 1
      const v2 = p5.sqrt(other.vx * other.vx + other.vy * other.vy); // and ball 2

      const cosine = p5.cos(angle);
      const sine = p5.sin(angle);
      const normalCosine = p5.cos(angle + p5.PI / 2); // cosine of angle + 90 degrees

      const newVx1 = v2 * p5.cos(a2 - angle) * cosine + v1 * p5.sin(a1 - angle) * normalCosine;
      const newVy1 = v2 * p5.cos(a2 - angle) * sine + v1 * p5.sin(a1 - angle) * sine;
      const newVx2 = v1 * p5.cos(a1 - angle) * cosine + v2 * p5.sin(a2 - angle) * normalCosine;
      const newVy2 = v1 * p5.cos(a1 - angle) * sine + v2 * p5.sin(a2 - angle) * sine;

      this.vx = newVx1 * 0.9; // apply new velocity to balls with 90% of original velocity (to prevent balls from sticking together)
      this.vy = newVy1 * 0.9;
      other.vx = newVx2 * 0.9;
      other.vy = newVy2 * 0.9;
    }
  }

  updateColor(p5) {
    const distance = p5.dist(this.x, this.y, this.trueX, this.trueY);
    if (distance > 10) {
      this.color = p5.lerpColor(this.color, this.targetColor, 0.05);
    } else {
      this.color = p5.lerpColor(this.color, this.getBaseColor(p5), 0.05);
    }
  }
}

class Ball {
  constructor(p5) {
    this.spawn(p5);
  }

  spawn(p5) {
    // ball starts outside the canvas
    this.x = p5.random(1) < 0.5 ? -10 : p5.width + 10;
    this.y = p5.random(p5.height);
    this.vx = p5.random(1, 5) * (this.x < 0 ? 1 : -1);
    this.vy = p5.random(-1, 1);
    this.color = p5.color(p5.random(255), p5.random(255), p5.random(255));
    this.radius = p5.random(10, 20);
  }

  draw(p5) {
    p5.noStroke();
    p5.fill(calculateFade(this.x, this.y, this.radius, this.color, p5));
    p5.circle(this.x, this.y, this.radius * 2);
  }

  update(p5) {
    this.x += this.vx;
    this.y += this.vy;
    if (this.x < -15 || this.x > p5.width + 15 || this.y < -15 || this.y > p5.height + 15) {
      this.spawn(p5);
    }
  }

  repel(x, y, maxDistance, maxForce, p5) {
    if (!x && !y) return; // prevent uninitialized movement
    const d = p5.dist(x, y, this.x, this.y);
    if (d < maxDistance) {
      const dx = x - this.x;
      const dy = y - this.y;
      const angle = p5.atan2(dy, dx);
      const force = p5.map(d, 0, maxDistance, maxForce, 0);
      this.vx -= p5.cos(angle) * force;
      this.vy -= p5.sin(angle) * force;
    }
  }

  checkCollision(other, p5) {
    const d = p5.dist(this.x, this.y, other.x, other.y);
    const minDist = this.radius + other.radius;
    if (d < minDist) { // collision detected
      const angle = p5.atan2(other.y - this.y, other.x - this.x); // angle of collision
      const overlap = minDist - d; // how much the balls overlap

      this.x -= p5.cos(angle) * overlap / 2; // move the balls apart ...
      this.y -= p5.sin(angle) * overlap / 2;
      other.x += p5.cos(angle) * overlap / 2;
      other.y += p5.sin(angle) * overlap / 2;

      const a1 = p5.atan2(this.vy, this.vx); // angle of velocity of ball 1
      const a2 = p5.atan2(other.vy, other.vx); // and ball 2
      const v1 = p5.sqrt(this.vx * this.vx + this.vy * this.vy); // current velocity of ball 1
      const v2 = p5.sqrt(other.vx * other.vx + other.vy * other.vy); // and ball 2

      const cosine = p5.cos(angle);
      const sine = p5.sin(angle);
      const normalCosine = p5.cos(angle + p5.PI / 2); // cosine of angle + 90 degrees

      const newVx1 = v2 * p5.cos(a2 - angle) * cosine + v1 * p5.sin(a1 - angle) * normalCosine;
      const newVy1 = v2 * p5.cos(a2 - angle) * sine + v1 * p5.sin(a1 - angle) * sine;
      const newVx2 = v1 * p5.cos(a1 - angle) * cosine + v2 * p5.sin(a2 - angle) * normalCosine;
      const newVy2 = v1 * p5.cos(a1 - angle) * sine + v2 * p5.sin(a2 - angle) * sine;

      this.vx = newVx1 * 0.9; // apply new velocity to balls with 90% of original velocity (to prevent balls from sticking together)
      this.vy = newVy1 * 0.9;
      other.vx = newVx2 * 0.9;
      other.vy = newVy2 * 0.9;
    }
  }
}
</script>

<style scoped>

</style>