import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalizedLoaded } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProjectsView from "../views/ProjectsView.vue";
import PageNotFoundView from "../views/PageNotFoundView.vue";
import ImprintView from "@/views/ImprintView.vue";
import i18n from "@/plugins/i18n";
import ProjectDetailView from "@/views/ProjectDetailView.vue";
import Lasergame2View from "@/views/Lasergame2View.vue";
import projectList from "@/components/projects/projectList";
import { l } from "@/components/projects/projectHelpers";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      tabTitle: undefined
    }
  },
  // {
  //   path: "/projects",
  //   meta: {
  //     tabTitle: i18n.global.t("projects.title"),
  //     breadcrumb() {return i18n.global.t("projects.title")}
  //   },
  //   children: [
  //     {
  //       path: "",
  //       component: ProjectsView
  //     },
  //     {
  //       path: ":id",
  //       name: "project",
  //       component: ProjectDetailView,
  //       meta: {
  //         tabTitle: i18n.global.t("projects.title"),
  //         breadcrumb(route: RouteLocationNormalizedLoaded) {
  //           return l(projectList.filter((project) => project.id === route.params.id)[0]?.title ?? "Project");
  //         }
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: "/play",
  //   name: "play-lasergame-2",
  //   component: Lasergame2View,
  //   meta: {
  //     tabTitle: i18n.global.t("lasergame2.title"),
  //     breadcrumb() {return i18n.global.t("lasergame2.title")}
  //   }
  // },
  {
    path: "/imprint",
    name: "imprint",
    component: ImprintView,
    meta: {
      tabTitle: i18n.global.t("imprint.title")
    }
  },
  {
    path: "/page-not-found",
    name: "404",
    component: PageNotFoundView,
    meta: {
      tabTitle: "404"
    }
  },
  { path: "/:pathMatch(.*)*", redirect: "/page-not-found" }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to) => {
  document.title = (to.meta.tabTitle ? (to.meta.tabTitle as string + " | ") : "") + i18n.global.t("app.name");
});

export default router;
