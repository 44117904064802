export default {
  "app": {
    "auto_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hey! I'm a fullstack software developer from Germany. Here you can find some of my projects and contact options."])},
    "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docs (de)"])},
    "mobile_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are currently issues displaying this website correctly on iOS. You may need to reload the page to update content displayed."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hendrik Wagner"])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
    "page_not_found_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are looking for does not exist."])},
    "page_not_found_go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
    "skip_to_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip to content"])},
    "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])}
  },
  "buttons": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
  },
  "contact": {
    "choose_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a way to reach out to me:"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is invalid"])},
    "email_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email required"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "message_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message required"])},
    "message_too_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message is too short"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
  },
  "i18n": {
    "change_to_de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to German"])},
    "change_to_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to English"])}
  },
  "imprint": {
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information in accordance with § 5 TMG"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])}
  },
  "lasergame2": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laser Temple 2"])}
  },
  "projects": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can find some of my projects."])},
    "technologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologies"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View project"])},
    "info_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The projects page is currently under construction and will reopen soon."])}
  }
}