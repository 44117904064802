<template>
  <v-container>
    <v-row class="text-center justify-center">
      <!-- description -->

      <v-col xxl="6" xl="8" md="8" sm="10" xs="12" class="mt-4 mb-2">
        <v-card
          class="ma-3 pa-5 on-primary-container--text"
          color="primary-container"
        >
          <v-card-title class="text-center">
            <h1>{{ $t("imprint.title") }}</h1>
          </v-card-title>
          <v-card-subtitle class="text-center">
            <h2>{{ $t("imprint.information") }}</h2>
          </v-card-subtitle>
          <v-card-text>
            <p>Hendrik Wagner</p>
            <p>Andreasteich 2</p>
            <p>35398 Gießen</p>
            <p>Germany</p>
          </v-card-text>
          <v-card-subtitle class="text-center">
            <h2>{{ $t("contact.title") }}</h2>
          </v-card-subtitle>
          <v-card-text>
            <p>{{ $t("contact.phone") }}: +49 (0) 178 710 2833</p>
            <p>
              {{ $t("contact.email") }}:
              <a
                href="mailto:kontakt@hendrikwagner.de"
                style="text-decoration: none; color: inherit;"
                >kontakt@hendrikwagner.de</a
              >
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Imprint",
};

// head-tag: noIndex

document.querySelector("head").innerHTML +=
  `<meta name="robots" content="noindex">`;
</script>

<style scoped></style>
