<template>
  <!--div
    style="position: fixed; top: 0; width: 100%; height: 50px; background-color: rgb(var(--v-theme-background)); z-index: 1"
  /-->
  <!-- jump to main content -->
  <v-btn
    href="#main"
    class="d-sr-only-focusable d-sr-only-focusable--active ml-3 "
    style="position: fixed; top: 86px; width: 320px; height: 24px; z-index: 1"
  >
    {{ $t("app.skip_to_content") }}
  </v-btn>
  <v-app-bar
    color="primary-container"
    elevation="5"
    border="5"
    class="bar mt-0"
    style="width: 100%; margin: 0 0"
  >
    <v-app-bar-nav-icon
      variant="text"
      @click.stop="drawer = !drawer"
      aria-label="Navigation"
    />

    <v-app-bar-title class="hidden-xs mx-0">
      <v-breadcrumbs class="hidden-xs" style="text-overflow: ellipsis" :items="breadcrumbs" active-class="primary--text">
        <template #title="{ item, index }">
          <span v-if="item && item.title && colors.length === item.title.length && index === 0">
            <v-card style="display: inline-block" class="mt-1 px-2 py-1" color="on-primary-container">
              <span v-for="(letter, index) in item.title" :key="index"
                    :style="'color:' + colors[index]">
                {{ letter }}
              </span>
            </v-card>
          </span>
          <span v-else>
            {{ item.title }}
          </span>
        </template>
      </v-breadcrumbs>
    </v-app-bar-title>

    <v-spacer v-if="$vuetify.display.xs" />

    <v-btn
      v-if="i18n.locale.value === 'de'"
      @click="setLocale('en')"
      icon
      aria-label="Switch to English / Zu Englisch wechseln"
    >
      <v-img
        src="@/assets/flags/de.svg"
        width="28"
        height="28"
      />
    </v-btn>
    <v-btn
      v-else
      @click="setLocale('de')"
      icon
      aria-label="Switch to German / Zu Deutsch wechseln"
    >
      <v-img
        src="@/assets/flags/uk.svg"
        width="28"
        height="28"
      />
    </v-btn>
    <!--
    Icons provided by Twemoji
    Copyright 2020 Twitter, Inc and other contributors
    Graphics licensed under CC-BY 4.0: https://creativecommons.org/licenses/by/4.0/
    -->
    <v-btn
      @click="cycleTheme"
      icon
      :aria-label="$t('app.theme')"
    >
      <v-icon v-if="themeSetting === 'light'" icon="mdi-white-balance-sunny" />
      <v-icon v-else-if="themeSetting === 'dark'" icon="mdi-weather-night" />
      <v-icon v-else icon="mdi-theme-light-dark" />
      <v-tooltip
        v-if="themeSetting === 'system'"
        activator="parent"
        location="bottom">
        {{ $t("app.auto_theme") }}
      </v-tooltip>
    </v-btn>
  </v-app-bar>

  <v-navigation-drawer
    v-model="drawer"
    :temporary="true"
    rounded
    class="drawer on-primary-container--text"
    style="height: max-content; min-height: 340px; margin-top: 24px; margin-left: 12px;"
    color="primary-container"
  >
    <!-- max-content does not work on iOS, hence min-height for approximated needed height -->
    <v-list :nav="true" aria-label="Navigation List">
      <v-list-item prepend-icon="mdi-home" :title="$t('home.title')" @click="router.push('/')" />
      <v-list-item prepend-icon="mdi-book" :title="$t('projects.title')" @click="router.push('/projects/')" />
      <v-list-item prepend-icon="mdi-mail" :title="$t('contact.title')"
                   @click="drawer = false; contactDialog = true;" />
      <!-- imprint -->
      <v-list-item prepend-icon="mdi-file-document" :title="$t('imprint.title')" @click="router.push('/imprint/')" />
      <v-divider class="ma-2" />
      <v-list-item prepend-icon="mdi-bookshelf" :title="$t('app.docs')" href="https://docs.hendrikwagner.de/"
                   append-icon="mdi-open-in-new" target="_blank" />
      <v-list-item prepend-icon="mdi-github" title="GitHub" href="https://github.com/hwgn/"
                   append-icon="mdi-open-in-new" target="_blank" />
      <v-list-item prepend-icon="mdi-server" title="Status" href="https://status.hendrikwagner.de/"
                   append-icon="mdi-open-in-new" target="_blank" />
    </v-list>
  </v-navigation-drawer>

  <v-dialog
    v-model="contactDialog"
  >
    <v-card :width="$vuetify.display.mobile ? '100%' : '400px'" style="align-self: center" color="secondary-container" class="on-secondary-container--text">
      <v-card-title>
        <v-icon class="ml-0 pl-0 mr-2">mdi-mail</v-icon>
        {{ $t("contact.title") }}
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="contact.name"
            :label="$t('contact.name')"
            required
            :rules="[v => !!v || $t('contact.name_required')]"
          />
          <v-text-field
            v-model="contact.email"
            :label="$t('contact.email')"
            required
            :rules="[v => !!v || $t('contact.email_required'),
            v => /^[a-zA-Z\d.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*$/.test(v) || $t('contact.email_invalid')]"
          />
          <v-textarea
            v-model="contact.message"
            :label="$t('contact.message')"
            required
            :rules="[v => !!v || $t('contact.message_required'),
            v => v.length > 20 || $t('contact.message_too_short')]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="contactDialog = false">{{ $t("buttons.cancel") }}</v-btn>
        <v-btn :disabled="!valid" color="primary" text
               @click="contactDialog = false; sendMessage().then((res) => console.log(res))">
          {{ $t("buttons.send") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed } from "vue";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { setLocale } from "@/plugins/i18n";
import { cycleTheme, themeSetting } from "@/plugins/theme";
import { useI18n } from "vue-i18n";
import { colors } from "@/plugins/colors";

const router = useRouter();
const route = useRoute();
const i18n = useI18n();
const drawer = ref(false);
const contactDialog = ref(false);
const contact = ref({
  name: "",
  email: "",
  message: ""
});
const valid = ref(false);

async function sendMessage() {
  const response = await fetch("https://submit-form.com/E30NbktO", {
    method: "POST",
    body: JSON.stringify({
      name: contact.value.name,
      email: contact.value.email,
      message: contact.value.message
    }),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  });
  contact.value = {
    name: "",
    email: "",
    message: ""
  };
  return response;
}

const breadcrumbs = computed(() => {
  const crumbs = [{
    title: i18n.t("app.name"),
    to: "/"
  }];

  for (const entry in route.matched) {
    if (route.matched[entry].meta && route.matched[entry].meta.breadcrumb) {
      crumbs.push({
        title: route.matched[entry].meta.breadcrumb(route),
        to: route.matched[entry].path
      });
    }
  }

  return crumbs;
});

onBeforeRouteUpdate(() => {
  breadcrumbs.effect.fn();
});

</script>

<style>
.pointer {
  cursor: pointer;
}

.v-breadcrumbs-item--disabled {
  opacity: 100% !important;
}
</style>