// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
import { darkTheme, lightTheme } from "@/plugins/theme";
import i18n from "@/plugins/i18n";

export default createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light: lightTheme,
      dark: darkTheme
    },
    options: {
      customProperties: true
    }
  },
  i18n
})
