import { ThemeDefinition } from "vuetify";
import { ref, Ref } from "vue";

// const themeLight = require("style!css!./assets/css/theme.light.css");
// import themeDark from "../assets/css/theme.dark.css";

// https://stackoverflow.com/a/57795495
let systemTheme =
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";

window
  .matchMedia("(prefers-color-scheme: dark)")
  .addEventListener("change", (event) => {
    if (themeSetting.value === "system") {
      theme.value = systemTheme = event.matches ? "dark" : "light";
    }
  });

export const themeSetting: Ref<string> = ref(
  window.localStorage.getItem("theme") || "system"
);

function fetchTheme(): string {
  switch (themeSetting.value) {
    case "dark":
    case "light":
      return themeSetting.value;
    case "system": // auto-detect system theme
    default: // no theme set
      return systemTheme;
  }
}

export const theme: Ref<string> = ref(fetchTheme());

export function setThemeSetting(newTheme: string) {
  window.localStorage.setItem("theme", newTheme);
  themeSetting.value = newTheme;
  theme.value = fetchTheme();
}

export function cycleTheme() {
  if (themeSetting.value === "system") {
    setThemeSetting("dark");
  } else if (themeSetting.value === "dark") {
    setThemeSetting("light");
  } else if (themeSetting.value === "light") {
    setThemeSetting("system");
  }
}

export const lightTheme: typeof ThemeDefinition = {
  dark: false,
  colors: {
    primary: "#006a63",
    "on-primary": "#ffffff",
    "primary-container": "#72f7eb",
    "on-primary-container": "#00201d",
    secondary: "#4a6360",
    "on-secondary": "#ffffff",
    "secondary-container": "#cce8e4",
    "on-secondary-container": "#051f1d",
    tertiary: "#47617a",
    "on-tertiary": "#ffffff",
    "tertiary-container": "#cee5ff",
    "on-tertiary-container": "#001d33",
    error: "#ba1a1a",
    "error-container": "#ffdad6",
    "on-error": "#ffffff",
    "on-error-container": "#410002",
    background: "#fafdfb",
    "on-background": "#191c1c",
    surface: "#fafdfb",
    "on-surface": "#191c1c",
    "surface-variant": "#dae5e2",
    "on-surface-variant": "#3f4947",
    outline: "#6f7977",
    "inverse-on-surface": "#eff1f0",
    "inverse-surface": "#2d3130",
    "inverse-primary": "#50dbcf",
    shadow: "#000000",
    "surface-tint": "#006a63",
    "outline-variant": "#bec9c6",
    scrim: "#000000",
  },
};

export const darkTheme: typeof ThemeDefinition = {
  dark: true,
  colors: {
    primary: "#50dbcf",
    "on-primary": "#003733",
    "primary-container": "#00504a",
    "on-primary-container": "#72f7eb",
    secondary: "#b1ccc8",
    "on-secondary": "#1c3532",
    "secondary-container": "#324b48",
    "on-secondary-container": "#cce8e4",
    tertiary: "#afc9e7",
    "on-tertiary": "#17324a",
    "tertiary-container": "#2f4961",
    "on-tertiary-container": "#cee5ff",
    error: "#ffb4ab",
    "error-container": "#93000a",
    "on-error": "#690005",
    "on-error-container": "#ffdad6",
    background: "#191c1c",
    "on-background": "#e0e3e1",
    surface: "#191c1c",
    "on-surface": "#e0e3e1",
    "surface-variant": "#3f4947",
    "on-surface-variant": "#bec9c6",
    outline: "#899391",
    "inverse-on-surface": "#191c1c",
    "inverse-surface": "#e0e3e1",
    "inverse-primary": "#006a63",
    shadow: "#000000",
    "surface-tint": "#50dbcf",
    "outline-variant": "#3f4947",
    scrim: "#000000",
  },
};
